import Vue  from 'vue'
import Urls from '@/../config/url.js'
import XEUtils from 'xe-utils'

const module_name = 'adminMenu'
const url = Urls.adminMenu

const state = {
    list         : [],
    current      : {},
    data_loading : false,
    total_count  : 0,
    edit_window  : false,
    options_list : [],
}

var preprocessItem = function(item) {
    delete item.children
    return item
}

const actions = {
    get_list({commit, dispatch, rootState}, query_params) {
        let props = {
            url          : url,
            name         : module_name,
            query_params : query_params
        }

        return dispatch('app/get_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_options({commit, dispatch, rootState}){
        let props = {
            url          : url,
            name         : module_name,
            query_params : {fields: ['title','parent','sortn','url', 'hidden']}
        }

        return dispatch('app/get_list', props, { root: true }).then(() => {
            let init_list = JSON.parse(JSON.stringify(state.list))
            let tree = XEUtils.toArrayTree(init_list, { key: 'id', parentKey: 'parent', sortKey: 'sortn', reverse: false });
            
            let options_list = []
            tree.forEach(item => {
                if (!item.hidden) {
                    options_list.push({ value:  item.id, label: item.title})
                    if (item.children) {
                        item.children.forEach(subitem => {
                            if (!subitem.hidden) {
                                options_list.push({ value:  subitem.id, label: `-- ${item.title}/${subitem.title}`})
                            }
                        })
                    }
                }
                
            })
            commit('set', {type: 'options_list', items: options_list});
        })
    },

    fixmenu({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'fixmenu/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
